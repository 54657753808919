var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f1121bf13144cd9ac4de57bc5903441a497b1254"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/hcp";

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole } from '@sentry/integrations';

Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,

    initialScope: {
        tags: {
            application_name: 'HCP',
        },
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate:
        Number(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE) || 0.5,

    // Capture Replay for 10% of all sessions
    // Capture 100% of sessions with an error
    replaysSessionSampleRate:
        Number(process.env.NEXT_PUBLIC_REPLAYS_SESSION_SAMPLE_RATE) || 0.1,
    replaysOnErrorSampleRate:
        Number(process.env.NEXT_PUBLIC_REPLAYS_ON_ERROR_SAMPLE_RATE) || 1.0,

    // NOTE: The BrowserTracing and Replay was commented out to avoid performance issue
    integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay(),
        new CaptureConsole({
            levels: ['error'],
        }),
    ],
});

export default Sentry;
